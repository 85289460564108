import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { EnabledChat } from "@gathertown/gather-game-common/dist/src/public/GameMap"
import { VALID_SPACE_NAME_PATTERN } from "gather-common/dist/src/public/constants"
import {
  SpaceResource,
  WriteableSpaceSettingsSchema,
} from "gather-common/dist/src/public/resources/space"
import { SpacePlanOverride } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../httpAPI"
import { zodUuid } from "../zodHelpers"

const c = initContract()

export const spaces = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.Spaces,
    body: z.object({
      id: zodUuid.optional(),
      name: z.string().trim().regex(VALID_SPACE_NAME_PATTERN),
      settings: WriteableSpaceSettingsSchema,
      reason: z.string().optional(),
      password: z.string().optional(),
      modPassword: z.string().optional(),
      map: z.string().optional(),
      sourceSpace: zodUuid.optional(),
      enabledChats: z.array(z.nativeEnum(EnabledChat)).optional(),
      planType: z.nativeEnum(SpacePlanOverride).optional(),
    }),
    responses: { 200: c.type<SpaceResource>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.Space,
    responses: { 200: c.type<SpaceResource>() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.Space,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
})

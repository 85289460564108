import RecentActorsIcon from "@mui/icons-material/RecentActors"
import SearchIcon from "@mui/icons-material/Search"
import { SelectChangeEvent } from "@mui/material"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TablePagination from "@mui/material/TablePagination"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import TabWrapper from "components/dashboard/TabWrapper"
import MultipleSelectChip from "components/inputs/MultipleSelectChip"
import { useSpaceUsers } from "features/spaces/hooks"
import { applyPagination, applySort } from "features/tables/utils"
import UsersTable from "pages/dashboard/users/partials/UsersTable"
import { pluck } from "ramda"
import React, { ChangeEvent, FC, MouseEvent, useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { isCaseInsensitiveMatch } from "utils/filterLogic"

import { CoreRole } from "@gathertown/gather-game-common/dist/src/public/Player"
import { SpaceUserResource } from "gather-common/dist/src/public/resources/space"
import { compact } from "gather-common-including-video/dist/src/public/fpHelpers"

interface Props {
  spaceId?: string
}

const UsersTab: FC<Props> = ({ spaceId }) => {
  const { isLoading, data } = useSpaceUsers(spaceId)
  const allUsers = useMemo(() => data || [], [data])
  const navigate = useNavigate()

  const [sort, setSort] = useState("email|asc") // Email (asc) will be the default option
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(5)
  const [emailQuery, setEmailQuery] = useState<string>("")
  const [selectedRoles, setSelectedRoles] = useState<CoreRole[]>([])
  const rolesList: string[] = Object.values(CoreRole).sort()

  const toggleSortOrder = useCallback(() => {
    const currentSortAsc = sort === "email|asc"

    if (currentSortAsc) {
      setSort("email|desc")
    } else {
      setSort("email|asc")
    }
  }, [setSort, sort])

  const handleSelectedRolesChange = (event: SelectChangeEvent<CoreRole[]>) => {
    const {
      target: { value },
    } = event
    const result =
      typeof value === "string"
        ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (value.split(",") as CoreRole[]) // autofill returns a string but we know the string array only has AdminRoleTypes in it
        : value
    setSelectedRoles(result)
  }

  const handleEmailQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailQuery(event.target.value)
  }

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page)
    },
    [setCurrentPage],
  )

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLimit(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [setLimit, setCurrentPage],
  )

  const applyFilters = useCallback(
    (query: string, users: SpaceUserResource[]) =>
      users.filter((user: SpaceUserResource) => {
        let matches = true

        if (query) {
          matches =
            isCaseInsensitiveMatch(user.email || "", query) ||
            isCaseInsensitiveMatch(user.id.toString(), query)
        }

        if (selectedRoles.length > 0) {
          matches = matches && selectedRoles.some((r) => user.role === r)
        }

        return matches
      }),
    [selectedRoles],
  )

  const usersToDisplay: SpaceUserResource[] = useMemo(() => {
    const filteredUsers = applyFilters(emailQuery, allUsers)
    const sortedUsers = applySort<SpaceUserResource>(filteredUsers, sort)
    return applyPagination<SpaceUserResource>(sortedUsers, currentPage, limit)
  }, [allUsers, applyFilters, emailQuery, currentPage, limit, sort])

  const handleOpenUsersInBulkUserManager = useCallback(() => {
    navigate("/dashboard/bulk-users", {
      state: {
        emails: compact(pluck("email", usersToDisplay)),
      },
    })
  }, [usersToDisplay])

  return (
    <TabWrapper>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          mb: 1,
          p: 2,
        }}
      >
        <Box
          sx={{
            width: 400,
            ml: -1,
            mr: 2,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleEmailQueryChange}
            placeholder="Search by Email or ID"
            value={emailQuery}
            variant="outlined"
          />
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <MultipleSelectChip
            options={rolesList}
            value={selectedRoles}
            onChange={handleSelectedRolesChange}
            label="Filter by Roles"
          />
        </Box>

        <Box marginLeft={2}>
          <Tooltip title="Open all emails in Bulk Manager Users tool">
            <IconButton size="small" onClick={handleOpenUsersInBulkUserManager}>
              <RecentActorsIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

      {!isLoading && data && (
        <Card>
          {usersToDisplay.length > 0 && (
            <>
              <UsersTable
                users={usersToDisplay}
                onToggleSort={toggleSortOrder}
                currentSortAsc={sort === "email|asc"}
              />
              <TablePagination
                component="div"
                count={allUsers.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={currentPage}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
              />
            </>
          )}
        </Card>
      )}
    </TabWrapper>
  )
}

export default UsersTab

type CustomErrorOptions<M extends Record<string, unknown> | undefined = undefined> = {
  defaultMessage?: string | ((metadata: M) => string)
}

/**
 * Usage on this one is a little weird, we do a trick where we create an extra function to specify
 * only one of the type generics and allow the other one to be inferred from the params.
 *
 *     createErrorClassWithMetadata<{ specialData: string }>()(errorTypeDefinition)
 *
 *  This will give instances a `.metadata` property with the shape you specify.
 */
// TODO [Rebuild] add `NoInfer` type on the M generic when we upgrade to TS 5.4
export const createErrorClassWithMetadata =
  <M extends Record<string, unknown>>() =>
  <T extends Record<string, string>>(
    groupName: string,
    types: T,
    options?: CustomErrorOptions<M>,
  ) =>
    createErrorClassGroup<typeof types, M>(groupName, types, options)

/**
 * Helper to create a custom error class with sub error types. This function produces a class that
 * has properties that reflect the properties of the "error types" you pass in, and these point to
 * unique subclass definitions.
 *
 *     const MyError = createErrorClass("MyError", { Foo: "Foo", Bar: "Bar" })
 *     new MyError.Foo("my foo error message")
 *
 * The error types definition must include a prop `_name` which provides a runtime string identifier
 * for the error grouping.
 *
 * `instanceof` checks behave as you'd expect.
 *
 * Because these are dynamically created classes without the `class` keyword, you need to be careful
 * when using the type as a parameter. When you do this:
 *
 *     class Foo {}
 *     const fn = (a: Foo) => {}
 *
 * It implicitly knows that `a` is an _instance of_ `Foo`, not the `Foo` class itself. This is not
 * the case for dynamically created classes. For that, you need to use a helper:
 *
 *     const Foo = class Foo {}
 *     const fn = (a: InstanceType<typeof Foo>) => {}
 */
export const createErrorClassGroup = <
  T extends Record<string, string>,
  M extends Record<string, unknown> | undefined = undefined,
>(
  groupName: string,
  types: T,
  options: CustomErrorOptions<M> = {},
) => {
  const klass = class extends Error {
    constructor(public type: T[keyof T], message?: string, nativeErrorOptions?: ErrorOptions) {
      super(message, nativeErrorOptions)
      this.type = type
      Object.setPrototypeOf(this, new.target.prototype)
    }
  }

  const createNewErrorTypeClass = (type: T[keyof T]) => {
    class ErrorTypeClass extends klass {
      metadata: M

      constructor(message?: string, metadata?: M, nativeErrorOptions?: ErrorOptions) {
        const errorName = `${groupName}::${type}`
        // We need the cast here, TS being unhappy here is somewhat related to the weird casting
        // we're doing with the constructor args
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        super(type, message ?? getDefaultMessage(options, metadata as M), nativeErrorOptions)
        this.name = errorName
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this.metadata = metadata ?? ({} as Exclude<M, undefined>)
        // this.metadata = metadata
        Object.setPrototypeOf(this, new.target.prototype)
      }
    }
    // 🚨 This is a sketchy type cast! Make sure it stays in sync with the
    // `ErrorTypeClass#constructor` signature.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return ErrorTypeClass as unknown as M extends undefined
      ? new (
          message?: string,
          metadata?: undefined,
          nativeErrorOptions?: ErrorOptions,
        ) => InstanceType<typeof ErrorTypeClass>
      : new (
          message: string | undefined,
          metadata: M,
          nativeErrorOptions?: ErrorOptions,
        ) => InstanceType<typeof ErrorTypeClass>
  }

  Object.keys(types).forEach((type) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions
    ;(klass as any)[type] = createNewErrorTypeClass(type as T[keyof T])
  })

  // 🚨 This is a sketchy type cast!
  // Overrides the class with methods that are the same as the keys of the error types object
  // passed in, pointing to newly new error subclasses. We have to manually cast it here.
  // Make sure to keep this type aligned with what you do inside the class definitions!
  //
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return klass as typeof klass & {
    [key in keyof T]: ReturnType<typeof createNewErrorTypeClass>
  }
}

export const createErrorClass = <M extends Record<string, unknown> | undefined = undefined>(
  name: string,
  options: CustomErrorOptions<M> = {},
) => {
  class ErrorTypeClass extends Error {
    metadata: M

    constructor(message?: string, metadata?: M, nativeErrorOptions?: ErrorOptions) {
      super(
        // TODO [Rebuild] fix fallback message, maybe unify this constructor logic somehow
        // We need the cast here, TS being unhappy here is somewhat related to the weird casting
        // we're doing with the constructor args
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        message ?? getDefaultMessage(options, metadata as M) ?? `${name} error occurred`,
        nativeErrorOptions,
      )
      this.name = name
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      this.metadata = metadata ?? ({} as M)
      Object.setPrototypeOf(this, new.target.prototype)
    }
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return ErrorTypeClass as unknown as M extends undefined
    ? new (
        message?: string,
        metadata?: undefined,
        nativeErrorOptions?: ErrorOptions,
      ) => InstanceType<typeof ErrorTypeClass>
    : new (
        message: string | undefined,
        metadata: M,
        nativeErrorOptions?: ErrorOptions,
      ) => InstanceType<typeof ErrorTypeClass>
}

const getDefaultMessage = <M extends Record<string, unknown> | undefined = undefined>(
  options: CustomErrorOptions<M>,
  metadata: M,
): string | undefined => {
  if ("defaultMessage" in options) {
    const { defaultMessage } = options
    return typeof defaultMessage === "function" ? defaultMessage(metadata) : defaultMessage
  }
  return undefined
}

export enum RequestMethod {
  "GET" = "GET",
  "POST" = "POST",
  "PATCH" = "PATCH",
  "DELETE" = "DELETE",
  /** Use PATCH instead: https://www.notion.so/gathertown/http-server-standards-748d5e2b891844fda302fd17d5cf8bff?pvs=4#e8083eb5003d4853bc47c13cfce1edc6 */
  "PUT" = "PUT",
}

export enum HttpV2Paths {
  _Template = "/_template/:id",
  /* Releases */
  BrowserClientReleases = "/releases/browser/:browserName/:releaseCommit/:releaseChannel",
  DesktopClientReleases = "/releases/desktop/:platform/:releaseVersion/:releaseChannel",
  DesktopClientLatest = "/releases/desktop/latest",

  /* Users */
  UserSpaces = "/users/:userId/spaces",
  UserSpaceAccess = "/users/:userId/spaces/:spaceId/access",
  UserRecentSpaces = "/users/:userId/recent-spaces",
  UserOwnedSpaces = "/users/:userId/owned-spaces",
  UserDefaultWearables = "/users/me/available-wearables",
  UserWearables = "/users/:userId/wearables",
  UserInitialOutfit = "/users/me/initial-outfit",
  User = "/users/:userId",
  UserData = "/users/:userId/data",
  UserDataField = "/users/:userId/data/:field",
  UserMaps = "/users/:userId/maps",
  UserFiles = "/users/:userId/files",
  UserFile = "/users/:userId/files/:fileId",
  UserDevice = "/users/:userId/devices/:deviceToken",

  /* Spaces */
  Spaces = "/spaces",
  Space = "/spaces/:spaceId",
  SpaceUsers = "/spaces/:spaceId/users",
  SpaceUser = "/spaces/:spaceId/users/:userId",
  SpaceUserCalendars = "/spaces/:spaceId/users/:userId/calendars",
  SpaceUserRoles = "/spaces/:spaceId/users/:userId/roles",
  SpaceUserOnboarding = "/spaces/:spaceId/users/:userId/onboarding",
  SpaceOnboardingChecklist = "/spaces/:spaceId/users/me/onboarding-checklist",
  SpaceUserSettings = "/spaces/:spaceId/users/:userId/settings",
  SpaceUsersRequests = "/spaces/:spaceId/membership-requests",
  SpaceUserRequest = "/spaces/:spaceId/users/:userId/membership-request",
  SpacePerfSurvey = "/spaces/:spaceId/surveys/perf",
  SpaceOnboardingSurvey = "/spaces/:spaceId/surveys/onboarding",
  SpaceCalendarAuths = "/spaces/:spaceId/calendar-auths",
  SpaceCalendarAuth = "/spaces/:spaceId/calendar-auths/:accountId",
  SpaceCalendarEvents = "/spaces/:spaceId/calendar-events",
  SpaceCalendarRecurrent = "/spaces/:spaceId/calendars/recurrent",
  SpaceCalendarEvent = "/spaces/:spaceId/calendar-events/:calendarId/:eventId",
  SpaceCapacityWarning = "/spaces/:spaceId/capacity/warning",
  SpaceGameServerAssignment = "/spaces/:spaceId/game-server-assignment",
  SpaceGatewayServerAssignment = "/spaces/:spaceId/gateway-server-assignment",
  SpaceLogicServerAssignment = "/spaces/:spaceId/logic-server-assignment",
  SpaceSettings = "/spaces/:spaceId/settings",
  SpaceSettingsMap = "/spaces/:spaceId/settings/map",
  SpaceSettingsPassword = "/spaces/:spaceId/settings/password",
  SpaceSpawns = "/spaces/:spaceId/spawns",
  SpaceSpawnToken = "/spaces/:spaceId/spawn-tokens/:spawnTokenId",
  SpaceSpawnTokenDetails = "/spaces/:spaceId/spawn-token-details/:spawnTokenId",
  SpaceSpawnTokens = "/spaces/:spaceId/spawn-tokens",
  SpaceNookSpawnTokens = "/spaces/:spaceId/:mapId/nook-spawn-tokens",
  SpaceBannedIps = "/spaces/:spaceId/banned-ips",
  SpaceBannedIp = "/spaces/:spaceId/banned-ips/:ip",
  SpaceMaps = "/spaces/:spaceId/maps",
  SpaceMapsPreview = "/spaces/:spaceId/preview/maps",
  SpaceMap = "/spaces/:spaceId/maps/:mapId",
  SpaceMapSize = "/spaces/:spaceId/maps/:mapId/size",
  SpaceObjectTemplates = "/spaces/:spaceId/object-templates",
  SpacePasswordAttempt = "/spaces/:spaceId/password-attempt",
  SpaceNooksSpawnTokens = "/spaces/:spaceId/nooks",
  SpaceGuests = "/spaces/:spaceId/guests",
  SpaceGuest = "/spaces/:spaceId/guests/:guestId",
  SpaceFile = "/spaces/:spaceId/files/:fileId",
  SpaceFiles = "/spaces/:spaceId/files",
  SpaceTrial = "/spaces/:spaceId/trial",
  SpacePermissions = "/spaces/:spaceId/permissions",
  SpaceSSO = "/spaces/:spaceId/sso",
  SpaceSSOEmailDomains = "/spaces/:spaceId/sso/email-domains",
  SpaceSSOSettings = "/spaces/:spaceId/sso/settings",
  SpaceSSOLinkedSpaces = "/spaces/:spaceId/sso/linked-spaces",
  SpaceSSOLinkedSpaceSource = "/spaces/:spaceId/sso/linked-space-source",
  SpaceSSOEmailDomainVerification = "/spaces/:spaceId/sso/email-domain/verify",
  SpaceRecordings = "/spaces/:spaceId/recordings",
  SpaceRecordingPreSignedURL = "/spaces/:spaceId/recordings/:recordingId/pre-signed-url",
  SpaceRecordingBudget = "/spaces/:spaceId/recording-budget",
  SpaceMeeting = "/spaces/:spaceId/meetings/:eventId",

  /* Insights */
  SpaceInsights = "/spaces/:spaceId/insights/:metric",
  SpaceInsightsMetricDetails = "/spaces/:spaceId/insights-details/:metric",
  SpaceInsightsSummary = "/spaces/:spaceId/insights-summary/:metrics",

  /* Map Templates */
  TemplateMap = "/templateMap/:templateMapId",
  TemplateMaps = "/templateMaps",

  /* Hubspot */
  HubSpotCreateContact = "/hubspot/contacts/create",
  HubSpotUpdateContact = "/hubspot/contacts/update",
  HubSpotUpdateContactNumberProperties = "/hubspot/contacts/update-number-properties",

  /* Email Invites */
  Invites = "/invites",

  /* Support Requests */
  SupportRequests = "/support-requests",

  /* OTP Auth */
  AuthOtpRequests = "/auth/otp-requests",
  AuthOtpRequestsVerify = "/auth/otp-requests/verify",

  /* SSO Auth */
  AuthSSORequests = "/auth/sso/initiate",
  AuthSSOTokenSwap = "/auth/sso/token-swap",
  AuthSSOUserIsMemberOfActiveOrganizationByEmail = "/auth/sso/member",

  /* Google Auth */
  AuthGoogleTokenSwap = "/auth/google/token",
  AuthGoogleSignInAuthUrl = "/auth/google/authorize",

  /* Invite Tokens */
  InviteTokens = "/invite-tokens",
  InviteToken = "/invite-tokens/:inviteToken",

  /* Discount Codes */
  Discount = "/discounts/:discountCode",

  /* Wearables */
  Wearables = "/wearables",
  Wearable = "/wearables/:wearableId",

  /* User Ids */
  UserIds = "/users/:email/id",

  /* Gather Admin */
  AdminAvClientStateSessions = "/admin/av-client-state-sessions",
  AdminAvClientStateSession = "/admin/av-client-state-sessions/:sessionId",
  AdminAvClientStateUserFeedback = "/admin/av-client-state-sessions/:sessionId/userFeedback",
  AdminCatalogItems = "/admin/catalogItems",
  AdminCatalogItemsBulk = "/admin/catalogItem/bulk",
  AdminCatalogItem = "/admin/catalogItem/:catalogItem",

  AdminAuthUsers = "/admin/users",
  AdminAuthUser = "/admin/users/email-or-id/:userId",
  AdminAuthUserDevices = "/admin/users/:userId/devices",
  AdminAuthUserSendPushNotifications = "/admin/users/:userId/notifications",
  AdminAuthUserSpaces = "/admin/users/:userId/spaces",
  AdminObjectTemplates = "/admin/object-templates",
  AdminObjectTemplatesBulk = "/admin/object-templates/bulk",
  AdminObjectTemplate = "/admin/object-templates/:objectTemplateId",
  AdminObjectTemplateVariants = "/admin/object-templates/:objectTemplateId/variants",
  AdminObjectTemplateVariant = "/admin/object-templates/:objectTemplateId/variants/:objectVariantId",
  AdminObjectTemplateCategoryGroup = "/admin/object-templates-categories/:categoryId",
  AdminObjectTemplateCategoryGroups = "/admin/object-templates-categories",
  AdminSpace = "/admin/spaces/:spaceId",
  AdminSpaceCapacity = "/admin/spaces/:spaceId/capacity",
  AdminSpaceMap = "/admin/spaces/:spaceId/maps/:mapId",
  AdminSpaceMaps = "/admin/spaces/:spaceId/maps",
  AdminSpaceUsers = "/admin/spaces/:spaceId/users",
  AdminSpaceUserGrapes = "/admin/spaces/:spaceId/users/:userId/grapes",
  AdminSpaceUserRecordings = "/admin/spaces/:spaceId/users/:userId/recordings",
  AdminSpaceUserRecording = "/admin/spaces/:spaceId/users/:userId/recordings/:recordingId",
  SuperAdminUserRoles = "/admin/super-admin-users/:adminUserId/roles",
  SuperAdminUsers = "/admin/super-admin-users",
  SuperAdminUser = "/admin/super-admin-users/:adminUserId",
  AdminWearables = "/admin/wearables",
  AdminWearable = "/admin/wearables/:wearableId",
  AdminWearablePart = "/admin/wearable-part/:wearablePartId",
  AdminWearableImage = "/admin/wearable-image",

  /* Object Templates */
  ObjectTemplateCategoryGroups = "/object-templates/category-groups",

  /* Gather RW */
  RWOfficeConfigurationConfigure = "/rw/spaces/:space/office-configuration/configure",
  RWOfficeConfigurationConfigureDefaultMapId = "/rw/office-configuration/default-map-id/:source",

  /* Cloudflare */
  CloudflareSiteVerify = "/integrations/cloudflare/siteverify",

  /* Third Party Service Webhooks */
  WorkOSWebhook = "/webhooks/workos/8XIspKIObRYIHTDXVeCTuqEe", // WorkOS Webhook ObfuscatedUrl
  HookStripePayment = "/hooks/stripe/payment",
  HookAssemblyAiTranscription = "/hooks/assemblyai/transcription",

  /* Internal Service Webhooks (S3) */
  HookAVRecordingS3 = "/hooks/recordings/s3",
  HookAVAudioRecordingS3 = "/hooks/audio-recordings/s3",
  HookAVMeetingSummaryS3 = "/hooks/meeting-summaries/s3",

  /* Scheduled Tasks */
  // Beware that changing this will require changing the values in the `Google Cloud Scheduler` service as well
  ScheduledDeleteRecordings = "/scheduled-tasks/delete-recordings",

  /* Chrome Extension Logs */
  PublishLog = "/internal/logs",

  /* NewRelic */
  NewRelicCustomEvents = "/newrelic/custom-events",

  /* Files */
  UploadImages = "/files/images",

  UserOutfitTemplates = "/outfits/templates",
  SpaceAreas = "/spaces/:spaceId/areas",
  SpaceMeetings = "/spaces/:spaceId/meetings",

  // TODO [Rebuild] [Experimentation]: Remove
  /* Experimental stuff for v2 */

  SpaceMeetingV2 = "/spaces/:spaceId/meetings/:meetingId",
  ExperimentalSpaceV2 = "/experimental/space-v2/:spaceId",
  ExperimentalSpacesV2 = "/experimental/spaces-v2/",

  /* Zapier */
  ZapierRecordings = "/integrations/zapier/recordings",
}

// Beware that changing this will require changing the values in the external service as well
export enum HttpOAuthPaths {
  GoogleCalendar = "/google-calendar/callback",
  Microsoft = "/microsoft/callback",
  GoogleSignIn = "/signin/google/callback",
  SSOSignIn = "/signin/sso/callback",
}

// Sensible default used for `:user` when path params are not provided
export const DEFAULT_PATH_PARAM_USER = "me" // nosemgrep

// TODO [Rebuild] [Experimentation]: Remove

import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpaceV2 } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const spacesV2 = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.ExperimentalSpacesV2,
    body: z.object({
      // Non-ephemeral params:
      name: z.string(),
    }),
    // this should eventually change as we want to control what fields are returned to the client
    responses: { 200: c.type<SpaceV2>() },
  },
})

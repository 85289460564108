import { GameMapHttpPublic } from "@gathertown/gather-game-common/dist/src/public/GameMap"
import { SpaceResource } from "gather-common/dist/src/public/resources/space"

// this is a public facing type, hence GameMapHttpPublic, not GameMapDB
export interface AdminSpaceMap extends GameMapHttpPublic {
  spaceId: string
}
export type MapObject = NonNullable<AdminSpaceMap["objects"]>[number]

export { Interaction } from "@gathertown/gather-game-common/dist/src/public/GameMap"

export interface AdminSpace extends SpaceResource {
  maxCapacity?: number
}

import { validateSpaceId } from "gather-common/dist/src/public/stringHelpers"
import { isNil, isNotNilAndNotEmpty } from "gather-common-including-video/dist/src/public/fpHelpers"
import { Env } from "gather-env-config/dist/src/public/env"
import { paths } from "./pathConstants"

// Returns a path with params interpolated.
// Lightweight implementation of https://reactrouter.com/utils/generate-path without wildcard matching
export const generatePathWithoutWildcards = (
  originalPath: string,
  params: {
    [key: string]: string | null
  } = {},
) => {
  const prefix = originalPath.startsWith("/") ? "/" : ""

  const segments = originalPath
    .split(/\/+/)
    .map((segment) => {
      const keyMatch = segment.match(/^:(\w+)(\??)$/)
      if (keyMatch) {
        const [, key, optional] = keyMatch
        if (key) {
          const isOptional = optional === "?"
          const param = params[key]
          if (!isOptional && isNil(param)) throw new Error(`Missing ":${key}" param`)
          return param ?? ""
        }
      }
      // Remove remaining optional segments
      return segment.replace(/\?$/g, "")
    })
    .filter((segment) => isNotNilAndNotEmpty(segment))

  return prefix + segments.join("/")
}

export const getURLBaseDomain = (url: string) => {
  const parsedUrl = new URL(url)
  const domainParts = parsedUrl.host.split(".")
  const domainEndParts = domainParts.slice(-2)

  const domain = domainEndParts[0]
  const tld = domainEndParts[1]

  if (!domain || !tld) return null

  return `${domain}.${tld}`
}

const gatherLocalOriginRegex = /^https?:\/\/((?:localhost:(?:3000|8080))|(?:.*ngrok(?:-free)?.app))/

export const isLocalGatherOrigin = (url: string) => gatherLocalOriginRegex.test(url)

export const isURLFromValidGatherOrigin = (url: string) => {
  // need to check the environment here directly so that we can mock it out in the test
  const isLocalOrTest =
    process.env.ENVIRONMENT === Env.local || process.env.ENVIRONMENT === Env.test
  return getURLBaseDomain(url) === "gather.town" || (isLocalOrTest && isLocalGatherOrigin(url))
}

export function getPathToHome() {
  return `/${paths.app}`
}

export const SPACE_PATH_PATTERN = `/${paths.app}/:spaceId`
export const SPACE_STUDIO_PATH_PATTERN = `/${paths.studio}/:spaceId`

// Use `getHumanReadableSpacePath` when creating a space path that is exposed to the user, e.g. a link copied to their clipboard.
export function getSpacePathFromId(spaceId: string): string {
  const constructedSpaceId = encodeURIComponent(spaceId)
  return generatePathWithoutWildcards(SPACE_PATH_PATTERN, {
    spaceId: constructedSpaceId,
  })
}

// Use `getHumanReadableSpacePath` when creating a space path that is exposed to the user, e.g. a link copied to their clipboard.
export function getSpacePathFromIdOrThrow(spaceId: string | undefined | null): string {
  if (isNil(spaceId)) throw new Error("Invalid spaceId in getSpacePathFromIdOrThrow")
  return getSpacePathFromId(spaceId)
}

// Use this util when creating a space path that is exposed to the user, e.g. a link copied to their clipboard.
// We include the space name in these cases for clarity on which space the link points to.
// This is not necessary in all cases because we inject the updated space name when navigating to a space.
export function getHumanReadableSpacePath({
  spaceId,
  spaceName,
}: {
  spaceId: string
  spaceName: string
}): string {
  return getSpacePathFromIdOrThrow(`${spaceName}-${spaceId}`)
}

export function getSpaceStudioPathFromId(spaceId: string): string {
  const constructedSpaceId = encodeURIComponent(spaceId)
  return generatePathWithoutWildcards(SPACE_STUDIO_PATH_PATTERN, {
    spaceId: constructedSpaceId,
  })
}

export const DASHBOARD_PATH_PATTERN = `/${paths.dashboard}/:spaceId`
export function getDashboardPath(
  spaceId?: string,
  route?: string,
  subRoute?: string,
): string | null {
  if (isNil(spaceId)) return `/${paths.dashboard}`
  if (!validateSpaceId(spaceId)) return null

  const pathRoute = route ? `/${route}` : ""
  const pathSubRoute = route && subRoute ? `/${subRoute}` : ""
  return generatePathWithoutWildcards(`/${DASHBOARD_PATH_PATTERN}${pathRoute}${pathSubRoute}`, {
    spaceId: spaceId,
  })
}

export function getDashboardPathOrThrow(spaceId?: string, route?: string, subRoute?: string) {
  const path = getDashboardPath(spaceId, route, subRoute)
  if (isNil(path)) throw new Error("Invalid spaceId in getDashboardPathOrThrow")
  return path
}

export const getUrlFromSpaceId = (spaceId: string, baseUrl = "https://gather.town") => {
  const path = getSpacePathFromId(spaceId)
  if (!path) return ""
  return `${baseUrl}${path}`
}

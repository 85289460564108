import { initContract } from "@ts-rest/core"

import { SerializedAndNormalizedGSState } from "gather-game-logic/dist/src/public/gameLogicTypes"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const areas = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceAreas,
    responses: {
      200: c.type<SerializedAndNormalizedGSState<"MapAreaV2">["MapAreaV2"]>(),
    },
  },
})

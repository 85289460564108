import { initContract } from "@ts-rest/core"
import { z } from "zod"

import {
  CF_IP_LAT_HEADER,
  CF_IP_LONG_HEADER,
} from "gather-common-including-video/dist/src/public/ip"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const MAX_SEARCH_LIMIT = 500

export const gameServerAssignment = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceGameServerAssignment,
    headers: z.object({
      [CF_IP_LAT_HEADER]: z.string().optional(),
      [CF_IP_LONG_HEADER]: z.string().optional(),
    }),
    responses: { 200: c.type<string | null>() },
  },
})

import { initContract } from "@ts-rest/core"

import { avClientStateSessions } from "./contracts/admin/avClientStateSessions/avClientStateSessions"
import { feedback } from "./contracts/admin/avClientStateSessions/feedback"
import { objectTemplateCategories } from "./contracts/admin/objectTemplateCategories"
import { objectTemplates as objectTemplatesForAdmin } from "./contracts/admin/objectTemplates"
import { variants as adminObjectTemplateVariants } from "./contracts/admin/objectTemplates/variants"
import { spaces as adminSpaces } from "./contracts/admin/spaces"
import { capacity as adminSpaceCapacity } from "./contracts/admin/spaces/capacity"
import { maps as adminSpaceMaps } from "./contracts/admin/spaces/maps"
import { users as adminSpaceUsers } from "./contracts/admin/spaces/users"
import { superAdminUsers } from "./contracts/admin/superAdminUsers"
import { roles as superAdminUserRoles } from "./contracts/admin/superAdminUsers/roles"
import { devices as adminUserDevices } from "./contracts/admin/users/devices"
import { notifications as adminUserNotifications } from "./contracts/admin/users/notifications"
import { spaces as adminUserSpaces } from "./contracts/admin/users/spaces"
import { users as usersFromAdminPortal } from "./contracts/admin/users/users"
import { wearableImages as adminWearableImages } from "./contracts/admin/wearableImages"
import { wearableParts as adminWearableParts } from "./contracts/admin/wearableParts"
import { wearables as adminWearables } from "./contracts/admin/wearables"
import { google } from "./contracts/auth/google"
import { otpRequests } from "./contracts/auth/otpRequests"
import { sso as authSSO } from "./contracts/auth/sso"
import { spacesV2 as spacesV2EXPERIMENTAL } from "./contracts/experimental/spacesV2"
import { spaceV2 as spaceV2EXPERIMENTAL } from "./contracts/experimental/spaceV2"
import { images } from "./contracts/files/images"
import { hubspot } from "./contracts/hubspot"
import { cloudflare } from "./contracts/integrations/cloudflare"
// I would prefer to export the contract instance, but it gives a TS error:
//     Exported variable tsRestContract has or is using name tag from external module
//     ".../node_modules/@ts-rest/core/src/lib/type-utils" but cannot be named.
// The workaround is to create a new contract instance in each sub-file, it doesn't seem
// to have any negative effect, but it's weird and annoying boilerplate.
import { recordingsContract } from "./contracts/integrations/zapier/recordings"
import { logs } from "./contracts/internals/logs"
import { newRelic } from "./contracts/internals/newRelic"
import { invites } from "./contracts/invites"
import { inviteTokens } from "./contracts/inviteTokens"
import { categoryGroups } from "./contracts/objectTemplates/categoryGroups"
import { templates } from "./contracts/outfits/templates"
import { releases } from "./contracts/releases"
import { deleteRecordings } from "./contracts/scheduledTasks/deleteRecordings"
import { spaces } from "./contracts/spaces"
import { bannedIps } from "./contracts/spaces/bannedIps"
import { calendarEvents } from "./contracts/spaces/calendarEvents"
import { auths } from "./contracts/spaces/calendars/auths"
import { recurrent } from "./contracts/spaces/calendars/recurrent"
import { warning } from "./contracts/spaces/capacity/warning"
import { files as spaceFiles } from "./contracts/spaces/files"
import { gameServerAssignment } from "./contracts/spaces/gameServerAssignment"
import { gatewayServerAssignment } from "./contracts/spaces/gatewayServerAssignment"
import { guests } from "./contracts/spaces/guests"
import { insights } from "./contracts/spaces/insights"
import { logicServerAssignment } from "./contracts/spaces/logicServerAssignment"
import { maps } from "./contracts/spaces/maps"
import { nookSpawnTokens } from "./contracts/spaces/maps/nookSpawnTokens"
import { meetings } from "./contracts/spaces/meetings"
import { membershipRequests } from "./contracts/spaces/membershipRequests"
import { nooks } from "./contracts/spaces/nooks"
import { objectTemplates as spaceObjectTemplates } from "./contracts/spaces/objectTemplates"
import { passwordAttempts } from "./contracts/spaces/passwordAttempts"
import { permissions } from "./contracts/spaces/permissions"
import { recordings } from "./contracts/spaces/recordings"
import { preSignedURL } from "./contracts/spaces/recordings/preSignedURL"
import { settings } from "./contracts/spaces/settings"
import { spawns } from "./contracts/spaces/spawns"
import { spawnTokenDetails } from "./contracts/spaces/spawnTokenDetails"
import { spawnTokens } from "./contracts/spaces/spawnTokens"
import { sso } from "./contracts/spaces/sso"
import { emailDomains } from "./contracts/spaces/sso/emailDomains"
import { linkedSpaces } from "./contracts/spaces/sso/linkedSpaces"
import { source } from "./contracts/spaces/sso/linkedSpaces/source"
import { settings as ssoSettings } from "./contracts/spaces/sso/settings"
import { onboarding as surveyOnboarding } from "./contracts/spaces/surveys/onboarding"
import { perf } from "./contracts/spaces/surveys/perf"
import { users as spaceUsers } from "./contracts/spaces/users"
import { calendars } from "./contracts/spaces/users/calendars"
import { membershipRequests as userMembershipRequests } from "./contracts/spaces/users/membershipRequests"
import { onboarding } from "./contracts/spaces/users/onboarding"
import { onboardingChecklist } from "./contracts/spaces/users/onboardingChecklist"
import { roles } from "./contracts/spaces/users/roles"
import { setting } from "./contracts/spaces/users/setting"
import { supportRequests } from "./contracts/supportRequests"
import { templateMaps } from "./contracts/templateMaps"
import { users } from "./contracts/users"
import { data } from "./contracts/users/data"
import { devices } from "./contracts/users/devices"
import { files } from "./contracts/users/files"
import { id } from "./contracts/users/id"
import { spaces as userSpaces } from "./contracts/users/spaces"
import { access } from "./contracts/users/spaces/access"
import { wearables as userWearables } from "./contracts/users/wearables"
import { wearables } from "./contracts/wearables"
import { assemblyAiHooks } from "./contracts/webhooks/assemblyAi"
import { audioRecordingHook } from "./contracts/webhooks/audioRecording"
import { meetingSummaryHook } from "./contracts/webhooks/meetingSummary"
import { recordingHook } from "./contracts/webhooks/recording"
import { workos } from "./contracts/webhooks/workos"
import { areas as areasV2 } from "./v2/contracts/spaces/areas"
import { meetings as meetingsV2 } from "./v2/contracts/spaces/meetings"

const c = initContract()

export const contracts = c.router({
  v2: {
    spaces: {
      areas: areasV2,
      meetings: meetingsV2,
    },
  },
  auth: {
    otpRequests,
    sso: authSSO,
    google,
  },

  hubspot,

  files: {
    images,
  },

  integrations: {
    cloudflare,
  },

  internals: {
    newRelic,
    logs,
  },

  invites,

  inviteTokens,

  objectTemplates: {
    categoryGroups,
  },

  outfits: { templates },

  scheduledTasks: {
    deleteRecordings,
  },

  integration: {
    zapier: {
      recordings: recordingsContract,
    },
  },
  spaces: {
    ...spaces,
    calendars: {
      auths,
      recurrent,
    },
    capacity: {
      warning,
    },
    bannedIps,
    calendarEvents,
    files: spaceFiles,
    gameServerAssignment,
    gatewayServerAssignment,
    logicServerAssignment,
    guests,
    insights,
    maps: {
      ...maps,
      nookSpawnTokens,
    },
    meetings,
    membershipRequests,
    nooks,
    objectTemplates: spaceObjectTemplates,
    passwordAttempts,
    permissions,
    recordings: {
      ...recordings,
      preSignedURL,
    },
    sso: {
      ...sso,
      emailDomains,
      linkedSpaces: {
        ...linkedSpaces,
        source,
      },
      settings: ssoSettings,
    },
    settings,
    spawns,
    spawnTokens,
    spawnTokenDetails,
    surveys: {
      perf,
      onboarding: surveyOnboarding,
    },
    users: {
      ...spaceUsers,
      calendars,
      membershipRequests: userMembershipRequests,
      onboarding,
      onboardingChecklist,
      roles,
      setting,
    },
  },

  releases,

  supportRequests,

  templateMaps,

  users: {
    ...users,
    data,
    devices,
    files,
    id,
    spaces: {
      ...userSpaces,
      access,
    },
    wearables: userWearables,
  },

  wearables,

  webhooks: {
    workos,
    audioRecording: audioRecordingHook,
    recording: recordingHook,
    assemblyAi: assemblyAiHooks,
    meetingSummary: meetingSummaryHook,
  },
  admin: {
    avClientStateSessions: {
      ...avClientStateSessions,
      feedback,
    },
    users: {
      ...usersFromAdminPortal,
      notifications: adminUserNotifications,
      devices: adminUserDevices,
      spaces: adminUserSpaces,
    },
    objectTemplates: {
      ...objectTemplatesForAdmin,
      variants: adminObjectTemplateVariants,
    },
    objectTemplateCategories,
    spaces: {
      ...adminSpaces,
      capacity: adminSpaceCapacity,
      maps: adminSpaceMaps,
      users: adminSpaceUsers,
    },
    wearables: adminWearables,
    wearableImages: adminWearableImages,
    wearableParts: adminWearableParts,
    superAdminUsers: {
      ...superAdminUsers,
      roles: superAdminUserRoles,
    },
  },

  // TODO [Rebuild] [Experimentation]: Remove
  experimental: {
    spaceV2: spaceV2EXPERIMENTAL,
    spacesV2: spacesV2EXPERIMENTAL,
  },
})

// TODO [Rebuild] [Experimentation]: Remove

import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const spaceV2 = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.ExperimentalSpaceV2,
    responses: { 200: c.type<{ exists: boolean }>() },
  },
})

import { curry, identity, ifElse, keys, mapObjIndexed, paths, pipe, values, zipObj } from "ramda"

import { isDictionary } from "./utils"

export const VIDEO_STATS_KEYS_TO_PATH = {
  sfuURL: ["sfuURL"],
  state: ["state"],
  trackStates: ["tracks"],
  audioTrack: ["audioVideo", "summary", "audioTracks"],
  videoTrack: ["audioVideo", "summary", "videoTracks"],
  soundTrack: ["soundScreen", "summary", "audioTracks"],
  screenTrack: ["soundScreen", "summary", "videoTracks"],
}

export const getVideoStats = (
  keyToPath: Record<string, string[]>,
  playerInfo: { [player: string]: unknown },
): Record<string, unknown> =>
  mapObjIndexed(pipe(ifElse(isDictionary, extractVideoStats(keyToPath), identity)), playerInfo)

export const extractVideoStats = curry(
  (keyToPath: Record<string, string[]>, playerInfo: { [player: string]: unknown }) =>
    zipObj(keys(keyToPath), paths(values(keyToPath), playerInfo)),
)

import { Button, Link, TableCell, TableRow, Typography } from "@mui/material"
import ChipList from "components/dashboard/ChipList"
import React, { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { CoreRole } from "@gathertown/gather-game-common/dist/src/public/Player"
import { SpaceResource } from "gather-common/dist/src/public/resources/space"
import { createSpaceUserGrapes } from "../../../../api/spaces/users/grapes"

interface Props {
  space: SpaceResource
  roles?: CoreRole[]
  userId?: string
}

const UserSpaceRow: FC<Props> = ({ space, roles, userId }) => (
  <TableRow hover key={space.id}>
    <TableCell>
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/dashboard/spaces?space=${encodeURIComponent(space.id)}`}
        underline="always"
        variant="subtitle2"
      >
        {space.id}
      </Link>
    </TableCell>

    <TableCell>
      <Typography color="textSecondary" variant="body2">
        {space.name}
      </Typography>
    </TableCell>

    <TableCell>
      <ChipList list={roles || []} />
    </TableCell>

    <TableCell>
      {userId && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => createSpaceUserGrapes(userId, space.id)}
        >
          Grapes
        </Button>
      )}
    </TableCell>
  </TableRow>
)

export default UserSpaceRow

export {
  asUuid,
  asUuids,
  getEmailParts,
  getFirstNumberInString,
  replaceAt,
  sanitizeString,
  sortByFirstNumberInStringsComparator,
  truncateString,
  validateSpaceId,
} from "../stringHelpers"

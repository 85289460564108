import { initContract } from "@ts-rest/core"
import zod from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const assemblyAiHooks = c.router({
  transcription: {
    method: RequestMethod.POST,
    path: HttpV2Paths.HookAssemblyAiTranscription,
    headers: zod.object({
      "x-gather-auth-key": zod.string(),
    }),
    body: zod.object({
      transcript_id: zod.string(),
      status: zod.union([zod.literal("completed"), zod.literal("error")]),
    }),
    query: zod.object({
      recordingId: zod.string(),
    }),
    responses: { 200: c.noBody() },
  },
})

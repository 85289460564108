import { initContract } from "@ts-rest/core"

import { GameMapHttpPublic } from "@gathertown/gather-game-common/dist/src/public/GameMap"
import { MapDetailsRecord } from "gather-common/dist/src/public/resources/templates"
import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

export const templateMaps = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.TemplateMaps,
    responses: { 200: c.type<MapDetailsRecord>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.TemplateMap,
    responses: { 200: c.type<GameMapHttpPublic | null>() },
  },
})

import validator from "validator"
import { z } from "zod"

import { asUuid } from "gather-common/dist/src/public/stringHelpers"
import { FILE_SIZE_LIMIT } from "./imageUpload"

export const zodQueryBoolean = z.enum(["true", "false"]).transform((value) => value === "true")

// For all date validations in zod, we must use refine so that it calculates the correct value for
// new Data.now() instead of the stale value calculated at runtime
export const zodCoerceAndValidateDate = (validator: (d: Date) => boolean, message?: string) =>
  z.coerce.date().refine(validator, message)

export const zodCoerceDateAndCheckIsAfterNow = zodCoerceAndValidateDate(
  (d) => d > new Date(),
  "Date must be in the future.",
)

/**
 * Determines if the start date is earlier than the end date.
 */
export const datesRefinement = ({ startDate, endDate }: { startDate: string; endDate: string }) =>
  new Date(startDate) < new Date(endDate)

/**
 * Prevent SQL injection attacks by validating userIds against alpha-numeric characters
 */
export const alphanumericStringRefinement = (ids: string[]) =>
  ids.every((id) => validator.isAlphanumeric(id))

export const zodUuid = z.string().transform((value, ctx) => {
  try {
    return asUuid(value)
  } catch {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid UUID",
    })

    return z.NEVER
  }
})

// Transform to handle serialized versions of the buffer
export const ZodBuffer = z
  .custom<Buffer>()
  .transform((arg) => {
    if (arg instanceof Buffer) return arg

    return Buffer.from(arg)
  })
  .refine((v) => v.byteLength <= FILE_SIZE_LIMIT, "File exceeds maximum size limit of 5mb")

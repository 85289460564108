import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { CoreRole } from "@gathertown/gather-game-common/dist/src/public/Player"
import { SpaceUserResource } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const roles = c.router({
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceUserRoles,
    body: z.object({
      email: z.string().email().optional(),
      role: z.nativeEnum(CoreRole).optional(),
    }),
    responses: { 200: c.type<SpaceUserResource>() },
  },
})

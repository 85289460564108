/* External modules */
/* MUI Components */
import Box from "@mui/material/Box"
/* Local modules */
import { UPDATE_OBJECT_TEMPLATE_SCHEMA } from "features/objectTemplates/constants"
import { ObjectTemplateFields } from "features/objectTemplates/types"
import { Formik } from "formik"
import { whereEq } from "ramda"
import React, { FC, useMemo } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import { isInteractableTemplate, isTemplateType } from "gather-common/dist/src/public/utils"
import { Interaction } from "gather-http-common/dist/src/public/spaces"
import useUpdateObjectTemplate from "../../../../features/objectTemplates/mutations/useUpdateObjectTemplate"
import UpdateForm from "./UpdateForm"

interface Props {
  objectTemplate: ObjectTemplate
  templateId: string
}

const EditObjectTemplateForm: FC<Props> = ({ objectTemplate, templateId }) => {
  const navigate = useNavigate()
  const INITIAL_VALUES: ObjectTemplateFields = useMemo(() => {
    const baseFields = {
      desc: objectTemplate.desc ?? "",
      name: objectTemplate.name ?? "",
      tags: objectTemplate.tags ?? [],
      type: objectTemplate.type ?? Interaction.NONE,
      src: undefined, // This is set when a user browses for a file
      srcFileType: undefined, // This is set when a user browses for a file
      volume: objectTemplate.sound?.volume ?? 100,
      audioLoop: objectTemplate.sound?.loop ?? false,
      maxDistance: objectTemplate.sound?.maxDistance ?? 5,
      isPositional: objectTemplate.sound?.isPositional ?? false,
      // The update endpoint isn't listening for variants, so this is only here so ts is happy
      variants: [],
    }

    const interactiveFields =
      objectTemplate && isInteractableTemplate(objectTemplate)
        ? {
            distThreshold: objectTemplate.distThreshold,
            previewMessage: objectTemplate.previewMessage,
          }
        : {}

    const typeFields = {
      deterministicUrlPrefix:
        objectTemplate && isTemplateType(objectTemplate, Interaction.EMBEDDED_WEBSITE)
          ? objectTemplate.deterministicUrlPrefix
          : undefined,
      url:
        objectTemplate && isTemplateType(objectTemplate, Interaction.EMBEDDED_WEBSITE)
          ? objectTemplate.url
          : undefined,
      preview: undefined, // This is set when a user browses for a file
      previewFileType: undefined, // This is set when a user browses for a file
      blurb:
        objectTemplate && isTemplateType(objectTemplate, Interaction.POSTER)
          ? objectTemplate.blurb
          : undefined,
      image: undefined, // This is set when a user browses for a file
      imageFileType: undefined, // This is set when a user browses for a file
      video:
        objectTemplate && isTemplateType(objectTemplate, Interaction.VIDEO)
          ? objectTemplate.video
          : undefined,
      message:
        objectTemplate && isTemplateType(objectTemplate, Interaction.NOTE)
          ? objectTemplate.message
          : undefined,
    }

    return {
      ...baseFields,
      ...interactiveFields,
      ...typeFields,
    }
  }, [objectTemplate])

  const onSuccessfulCreation = () => {
    toast.success("Object Template was successfully updated.")
  }

  const onCreationError = () => {
    toast.error(`Error: Object Template could not be updated.`)
  }

  const { mutate: updateObjTemplate } = useUpdateObjectTemplate({
    onSuccess: onSuccessfulCreation,
    onError: onCreationError,
  })

  return (
    <Box sx={{ minWidth: 1100 }}>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={UPDATE_OBJECT_TEMPLATE_SCHEMA}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            // Formik touches all values before submission, so I need to check which values
            // have actually changed
            // Reference: https://formik.org/docs/guides/form-submission
            const { src, volume, audioLoop, maxDistance, isPositional, ...fields } = values
            const isEqualToNewSoundValues = whereEq({
              src,
              volume,
              audioLoop,
              maxDistance,
              isPositional,
            })
            const soundSettingsChanged = !isEqualToNewSoundValues(INITIAL_VALUES)

            const toSubmit = {
              ...fields,
              // only modify sound if a sound prop has changed
              ...(soundSettingsChanged && {
                sound: {
                  ...(src && { src }),
                  volume,
                  loop: audioLoop,
                  maxDistance,
                  isPositional,
                },
              }),
            }

            updateObjTemplate({ templateId, fields: toSubmit })
            setStatus({ success: true })
            setSubmitting(false)
            navigate(`/dashboard/mapmaker/objects/${templateId}`)
          } catch (error) {
            if (error instanceof Error) {
              const msg = error.message
              toast.error(msg)
            }
            setStatus({ success: false })
            setSubmitting(false)
          }
        }}
      >
        {(formik) => <UpdateForm oldTemplate={objectTemplate} {...formik} />}
      </Formik>
    </Box>
  )
}

export default EditObjectTemplateForm

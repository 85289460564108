
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  detectRuntime,
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.9.1
 * Query Engine version: 23fdc5965b1e05fc54e5f26ed3de66776b93de64
 */
Prisma.prismaVersion = {
  client: "5.9.1",
  engine: "23fdc5965b1e05fc54e5f26ed3de66776b93de64"
}

Prisma.PrismaClientKnownRequestError = () => {
  throw new Error(`PrismaClientKnownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  throw new Error(`PrismaClientUnknownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  throw new Error(`PrismaClientRustPanicError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  throw new Error(`PrismaClientInitializationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  throw new Error(`PrismaClientValidationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  throw new Error(`NotFoundError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  throw new Error(`sqltag is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  throw new Error(`empty is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  throw new Error(`join is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  throw new Error(`raw is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  throw new Error(`Extensions.getExtensionContext is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  throw new Error(`Extensions.defineExtension is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.ApiKeyScalarFieldEnum = {
  id: 'id',
  hash: 'hash',
  memo: 'memo',
  userId: 'userId',
  ssoOrganizationId: 'ssoOrganizationId',
  environment: 'environment',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RelationLoadStrategy = {
  query: 'query',
  join: 'join'
};

exports.Prisma.BrowserVideoClientStateSessionScalarFieldEnum = {
  id: 'id',
  playerId: 'playerId',
  spaceId: 'spaceId',
  sessionId: 'sessionId',
  session: 'session',
  hwGpuPowerLevel: 'hwGpuPowerLevel',
  hwGpuPerformance: 'hwGpuPerformance',
  hwMemory: 'hwMemory',
  hwConcurrency: 'hwConcurrency',
  hwMeetsMinSpecs: 'hwMeetsMinSpecs',
  connectionType: 'connectionType',
  connectionEffectiveType: 'connectionEffectiveType',
  domain: 'domain',
  env: 'env',
  version: 'version',
  commitHash: 'commitHash',
  buildTimestamp: 'buildTimestamp',
  electron: 'electron',
  userAgent: 'userAgent',
  userAgentBrowser: 'userAgentBrowser',
  userAgentOs: 'userAgentOs',
  userAgentVersion: 'userAgentVersion',
  operatingSystem: 'operatingSystem',
  operatingSystemVersion: 'operatingSystemVersion',
  avStrategy: 'avStrategy',
  message: 'message',
  translatedMessage: 'translatedMessage',
  liveKitSessionId: 'liveKitSessionId',
  liveKitParticipantId: 'liveKitParticipantId',
  agoraVideoId: 'agoraVideoId',
  agoraScreenId: 'agoraScreenId',
  agoraVideoChannelName: 'agoraVideoChannelName',
  agoraScreenChannelName: 'agoraScreenChannelName',
  participants: 'participants',
  affectedEveryone: 'affectedEveryone',
  isPaidSpace: 'isPaidSpace',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserDeviceScalarFieldEnum = {
  deviceToken: 'deviceToken',
  deviceType: 'deviceType',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalIntegrationScalarFieldEnum = {
  id: 'id',
  type: 'type',
  accountId: 'accountId',
  accountEmail: 'accountEmail',
  userId: 'userId',
  spaceId: 'spaceId',
  selectedCalendarIds: 'selectedCalendarIds',
  autoDndEnabled: 'autoDndEnabled',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FileScalarFieldEnum = {
  id: 'id',
  uploaderId: 'uploaderId',
  spaceId: 'spaceId',
  type: 'type',
  filePath: 'filePath',
  permission: 'permission',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GoogleTokenScalarFieldEnum = {
  id: 'id',
  externalIntegrationId: 'externalIntegrationId',
  id_token: 'id_token',
  refresh_token: 'refresh_token',
  expiry_date: 'expiry_date',
  access_token: 'access_token',
  token_type: 'token_type',
  scope: 'scope',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.InviteTokenScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  expiresAt: 'expiresAt',
  inviterId: 'inviterId',
  inviterName: 'inviterName',
  role: 'role',
  email: 'email',
  deskId: 'deskId',
  deskMapId: 'deskMapId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.LegacyInviteTokenScalarFieldEnum = {
  id: 'id',
  token: 'token',
  spaceId: 'spaceId',
  expirationDate: 'expirationDate',
  inviterId: 'inviterId',
  inviterDeviceId: 'inviterDeviceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectTemplateCategoryScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  categories: 'categories',
  name: 'name',
  order: 'order',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectTemplateScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  description: 'description',
  name: 'name',
  tags: 'tags',
  type: 'type',
  text: 'text',
  image: 'image',
  message: 'message',
  zoomLink: 'zoomLink',
  deterministicUrlPrefix: 'deterministicUrlPrefix',
  url: 'url',
  previewMessage: 'previewMessage',
  distThreshold: 'distThreshold',
  blurb: 'blurb',
  preview: 'preview',
  video: 'video',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectVariantScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  color: 'color',
  orientation: 'orientation',
  normal: 'normal',
  highlighted: 'highlighted',
  default: 'default',
  objectTemplateId: 'objectTemplateId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectVariantSpritesheetScalarFieldEnum = {
  id: 'id',
  currentAnim: 'currentAnim',
  spritesheetUrl: 'spritesheetUrl',
  frameWidth: 'frameWidth',
  frameHeight: 'frameHeight',
  objectVariantId: 'objectVariantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectVariantSpritesheetAnimationScalarFieldEnum = {
  id: 'id',
  name: 'name',
  useSequenceAsRange: 'useSequenceAsRange',
  sequence: 'sequence',
  loop: 'loop',
  frameRate: 'frameRate',
  objectVariantSpritesheetId: 'objectVariantSpritesheetId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ScreenAnnotationScalarFieldEnum = {
  annotationSessionId: 'annotationSessionId',
  drawerId: 'drawerId',
  annotationId: 'annotationId',
  drawerName: 'drawerName',
  pointerType: 'pointerType',
  pressure: 'pressure',
  tool: 'tool',
  size: 'size',
  color: 'color',
  strength: 'strength',
  points: 'points',
  serverDrawTime: 'serverDrawTime',
  dimsWidth: 'dimsWidth',
  dimsHeight: 'dimsHeight',
  dpi: 'dpi',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SurveyResponseScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  spaceId: 'spaceId',
  type: 'type',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RecordingScalarFieldEnum = {
  id: 'id',
  recordingClientId: 'recordingClientId',
  egressId: 'egressId',
  userId: 'userId',
  spaceId: 'spaceId',
  startedAt: 'startedAt',
  endedAt: 'endedAt',
  fileId: 'fileId',
  fileAvailable: 'fileAvailable',
  fileName: 'fileName',
  summarizable: 'summarizable',
  expiresAt: 'expiresAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RecordingExtraBudgetsScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  additionalBudgetHoursPerUser: 'additionalBudgetHoursPerUser',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SoundScalarFieldEnum = {
  id: 'id',
  src: 'src',
  volume: 'volume',
  loop: 'loop',
  maxDistance: 'maxDistance',
  isPositional: 'isPositional',
  objectTemplateId: 'objectTemplateId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceScalarFieldEnum = {
  id: 'id',
  isChatDisabled: 'isChatDisabled',
  isChatPersistDisabled: 'isChatPersistDisabled',
  isScreenshareDisabled: 'isScreenshareDisabled',
  isInviteDisabled: 'isInviteDisabled',
  isStaffAccessEnabled: 'isStaffAccessEnabled',
  isGuestCheckInEnabled: 'isGuestCheckInEnabled',
  isRecordingForMembersEnabled: 'isRecordingForMembersEnabled',
  isSpaceRWHangoutOnCreation: 'isSpaceRWHangoutOnCreation',
  isGlobalBuildEnabled: 'isGlobalBuildEnabled',
  isLoginRequired: 'isLoginRequired',
  isPreloadAllAssetsEnabled: 'isPreloadAllAssetsEnabled',
  gatherLabsFeaturesEnabled: 'gatherLabsFeaturesEnabled',
  emailDomains: 'emailDomains',
  autoPromoteMembersEmailDomains: 'autoPromoteMembersEmailDomains',
  autoPromoteMembersEnabled: 'autoPromoteMembersEnabled',
  bannedUserIdsOrIPs: 'bannedUserIdsOrIPs',
  enabledChats: 'enabledChats',
  hadCopyUploadErrors: 'hadCopyUploadErrors',
  isTemplate: 'isTemplate',
  map: 'map',
  modPassword: 'modPassword',
  name: 'name',
  officeConfigurationSourceSpace: 'officeConfigurationSourceSpace',
  password: 'password',
  reason: 'reason',
  roomCount: 'roomCount',
  serverURL: 'serverURL',
  styles: 'styles',
  writeId: 'writeId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  planOverride: 'planOverride'
};

exports.Prisma.SpaceCapacityWarningScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  capacity: 'capacity',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceCapacityWarningUserScalarFieldEnum = {
  id: 'id',
  spaceCapacityWarningId: 'spaceCapacityWarningId',
  userId: 'userId',
  createdAt: 'createdAt'
};

exports.Prisma.SpaceExtensionScalarFieldEnum = {
  id: 'id',
  enabled: 'enabled',
  args: 'args',
  type: 'type',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceRolePermissionOverrideScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  role: 'role',
  permission: 'permission',
  enabled: 'enabled',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceTemplateScalarFieldEnum = {
  id: 'id',
  name: 'name',
  desc: 'desc',
  features: 'features',
  images: 'images',
  areaType: 'areaType',
  tab: 'tab',
  subtab: 'subtab',
  size: 'size',
  numAdvancedObjects: 'numAdvancedObjects',
  hasFixedObjects: 'hasFixedObjects',
  pinned: 'pinned',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpawnTokenScalarFieldEnum = {
  id: 'id',
  mapId: 'mapId',
  spaceId: 'spaceId',
  type: 'type',
  spawnId: 'spawnId',
  deskOwnerId: 'deskOwnerId',
  nookId: 'nookId',
  eventId: 'eventId',
  timestampAt: 'timestampAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceWorkOSConnectionScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  workOSOrganizationId: 'workOSOrganizationId',
  state: 'state',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOSettingsScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  emailDomainVerificationCode: 'emailDomainVerificationCode',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOEmailDomainScalarFieldEnum = {
  id: 'id',
  domain: 'domain',
  spaceSSOSettingsId: 'spaceSSOSettingsId',
  verificationState: 'verificationState',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOLinkedSpaceScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  verified: 'verified',
  spaceSSOSettingsId: 'spaceSSOSettingsId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSendBirdApplicationScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  sendBirdApplicationId: 'sendBirdApplicationId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserScalarFieldEnum = {
  id: 'id',
  cdcSinkId: 'cdcSinkId',
  spaceId: 'spaceId',
  userFirestoreId: 'userFirestoreId',
  mapFirestoreId: 'mapFirestoreId',
  allowScreenPointer: 'allowScreenPointer',
  becameMemberAt: 'becameMemberAt',
  city: 'city',
  connected: 'connected',
  country: 'country',
  currentGuestUsageMinutes: 'currentGuestUsageMinutes',
  description: 'description',
  direction: 'direction',
  displayEmail: 'displayEmail',
  emojiStatus: 'emojiStatus',
  guestPassExpiresAt: 'guestPassExpiresAt',
  guestPassStatus: 'guestPassStatus',
  hasAccess: 'hasAccess',
  handRaisedAt: 'handRaisedAt',
  itemString: 'itemString',
  lastActiveMobileAt: 'lastActiveMobileAt',
  lastRaisedHandAt: 'lastRaisedHandAt',
  lastVisitedAt: 'lastVisitedAt',
  name: 'name',
  personalImageId: 'personalImageId',
  personalImageUrl: 'personalImageUrl',
  phone: 'phone',
  profileImageId: 'profileImageId',
  profileImageUrl: 'profileImageUrl',
  pronouns: 'pronouns',
  role: 'role',
  startedAt: 'startedAt',
  status: 'status',
  statusEndOption: 'statusEndOption',
  statusUpdatedAt: 'statusUpdatedAt',
  statusChangeSource: 'statusChangeSource',
  statusExpiresAt: 'statusExpiresAt',
  statusExpiresToStatus: 'statusExpiresToStatus',
  statusExpiresToChangeSource: 'statusExpiresToChangeSource',
  statusExpiresToExpireTime: 'statusExpiresToExpireTime',
  textStatus: 'textStatus',
  timezone: 'timezone',
  title: 'title',
  x: 'x',
  y: 'y',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserRequestScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId',
  updatedById: 'updatedById',
  spaceId: 'spaceId',
  status: 'status',
  statusChangeSource: 'statusChangeSource',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserSettingScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId'
};

exports.Prisma.SpaceUserSettingPinnedUserScalarFieldEnum = {
  spaceUserSettingId: 'spaceUserSettingId',
  pinnedSpaceUserId: 'pinnedSpaceUserId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserDeskScalarFieldEnum = {
  id: 'id',
  cdcSinkId: 'cdcSinkId',
  spaceUserId: 'spaceUserId',
  spaceId: 'spaceId',
  mapFirestoreId: 'mapFirestoreId',
  name: 'name',
  locked: 'locked',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  externalUserId: 'externalUserId',
  adminRoles: 'adminRoles',
  email: 'email',
  hasInitiatedSSOAuth: 'hasInitiatedSSOAuth',
  hubSpotContactId: 'hubSpotContactId',
  name: 'name',
  otpNumTries: 'otpNumTries',
  otpSecret: 'otpSecret',
  selectedLanguage: 'selectedLanguage',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserDeletionForStagingScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  email: 'email',
  completed: 'completed',
  attempts: 'attempts',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserFeedbackScalarFieldEnum = {
  id: 'id',
  feedback: 'feedback',
  name: 'name',
  email: 'email',
  spaceUrl: 'spaceUrl',
  reason: 'reason',
  device: 'device',
  gates: 'gates',
  isMobile: 'isMobile',
  deviceInfo: 'deviceInfo',
  performanceProblem: 'performanceProblem',
  sessionId: 'sessionId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserOutfitTemplateScalarFieldEnum = {
  id: 'id',
  type: 'type',
  order: 'order',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserOutfitScalarFieldEnum = {
  id: 'id',
  cdcSinkId: 'cdcSinkId',
  userId: 'userId',
  spaceUserId: 'spaceUserId',
  userOutfitTemplateId: 'userOutfitTemplateId',
  skin: 'skin',
  hair: 'hair',
  facialHair: 'facialHair',
  top: 'top',
  bottom: 'bottom',
  shoes: 'shoes',
  hat: 'hat',
  glasses: 'glasses',
  other: 'other',
  costume: 'costume',
  mobility: 'mobility',
  jacket: 'jacket',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.WearablePartScalarFieldEnum = {
  id: 'id',
  spritesheetUrl: 'spritesheetUrl',
  layer: 'layer',
  wearableId: 'wearableId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.WearableScalarFieldEnum = {
  id: 'id',
  color: 'color',
  name: 'name',
  type: 'type',
  subType: 'subType',
  style: 'style',
  isDefault: 'isDefault',
  previewUrl: 'previewUrl',
  startDate: 'startDate',
  endDate: 'endDate',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOnboardingScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  spaceId: 'spaceId',
  currentStep: 'currentStep',
  deskSelectionId: 'deskSelectionId',
  shouldSetDesk: 'shouldSetDesk',
  deskIdToAssign: 'deskIdToAssign',
  deskMapIdToAssign: 'deskMapIdToAssign',
  isComplete: 'isComplete',
  isActive: 'isActive',
  name: 'name',
  outfitTemplateId: 'outfitTemplateId',
  lastActiveAt: 'lastActiveAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOnboardingChecklistScalarFieldEnum = {
  id: 'id',
  maybeLater: 'maybeLater',
  spaceUserId: 'spaceUserId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOnboardingChecklistItemScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId',
  taskId: 'taskId',
  isComplete: 'isComplete',
  completionTime: 'completionTime',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CalendarEventV2ScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  externalCalendarId: 'externalCalendarId',
  googleCalendarEventId: 'googleCalendarEventId',
  outlookCalendarEventId: 'outlookCalendarEventId',
  meetingId: 'meetingId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CatalogItemV2ScalarFieldEnum = {
  id: 'id',
  category: 'category',
  family: 'family',
  type: 'type',
  description: 'description',
  tags: 'tags',
  version: 'version',
  lastSyncAuthoredAt: 'lastSyncAuthoredAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CatalogItemVariantV2ScalarFieldEnum = {
  id: 'id',
  color: 'color',
  orientation: 'orientation',
  originX: 'originX',
  originY: 'originY',
  default: 'default',
  catalogItemId: 'catalogItemId',
  collision: 'collision',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  dimensionsInPixels__width: 'dimensionsInPixels__width',
  dimensionsInPixels__height: 'dimensionsInPixels__height',
  offsetInPixels__top: 'offsetInPixels__top',
  offsetInPixels__left: 'offsetInPixels__left',
  mainRenderable__imageUrl: 'mainRenderable__imageUrl',
  mainRenderable__fold: 'mainRenderable__fold'
};

exports.Prisma.ExternalCalendarConnectionAccessV2ScalarFieldEnum = {
  id: 'id',
  externalCalendarConnectionId: 'externalCalendarConnectionId',
  externalCalendarId: 'externalCalendarId',
  primary: 'primary',
  color: 'color',
  visible: 'visible',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionAccessV2RoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionSecretsV2ScalarFieldEnum = {
  id: 'id',
  calendarConnectionId: 'calendarConnectionId',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionV2ScalarFieldEnum = {
  id: 'id',
  externalId: 'externalId',
  spaceId: 'spaceId',
  spaceUserId: 'spaceUserId',
  type: 'type',
  lastSyncedAt: 'lastSyncedAt',
  lastSyncTokenOrUrl: 'lastSyncTokenOrUrl',
  syncing: 'syncing',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionV2RoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarV2ScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  name: 'name',
  externalId: 'externalId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarV2RoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FileV2ScalarFieldEnum = {
  id: 'id',
  uploaderUserId: 'uploaderUserId',
  spaceId: 'spaceId',
  path: 'path',
  type: 'type',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FloorV2ScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GoogleCalendarEventV2ScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  externalEventId: 'externalEventId',
  status: 'status',
  summary: 'summary',
  description: 'description',
  location: 'location',
  startDateTime: 'startDateTime',
  startDate: 'startDate',
  startTimeZone: 'startTimeZone',
  endDateTime: 'endDateTime',
  endDate: 'endDate',
  endTimeZone: 'endTimeZone',
  attendees: 'attendees',
  recurrence: 'recurrence',
  recurringEventId: 'recurringEventId',
  eventType: 'eventType',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GroupUserV2ScalarFieldEnum = {
  id: 'id',
  groupId: 'groupId',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GroupV2ScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GuestPassV2ScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  spaceUserId: 'spaceUserId',
  hostId: 'hostId',
  state: 'state',
  requestedAt: 'requestedAt',
  respondedAt: 'respondedAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GuestPassV2RoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MapAreaV2ScalarFieldEnum = {
  id: 'id',
  mapEntityIdentifierId: 'mapEntityIdentifierId',
  parentAreaId: 'parentAreaId',
  parentGroupId: 'parentGroupId',
  parentObjectId: 'parentObjectId',
  relativeX: 'relativeX',
  relativeY: 'relativeY',
  deletedAt: 'deletedAt',
  spaceId: 'spaceId',
  mapId: 'mapId',
  mapAreaType: 'mapAreaType',
  capacity: 'capacity',
  name: 'name',
  doorways: 'doorways',
  wallsTexture: 'wallsTexture',
  floorTexture: 'floorTexture',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  dimensionsInTiles__width: 'dimensionsInTiles__width',
  dimensionsInTiles__height: 'dimensionsInTiles__height'
};

exports.Prisma.MapEntityIdentifierScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  floorId: 'floorId',
  chatChannelUrl: 'chatChannelUrl'
};

exports.Prisma.MapGroupV2ScalarFieldEnum = {
  id: 'id',
  mapEntityIdentifierId: 'mapEntityIdentifierId',
  parentAreaId: 'parentAreaId',
  parentGroupId: 'parentGroupId',
  parentObjectId: 'parentObjectId',
  relativeX: 'relativeX',
  relativeY: 'relativeY',
  deletedAt: 'deletedAt',
  spaceId: 'spaceId',
  mapId: 'mapId',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MapObjectV2ScalarFieldEnum = {
  id: 'id',
  mapEntityIdentifierId: 'mapEntityIdentifierId',
  parentAreaId: 'parentAreaId',
  parentGroupId: 'parentGroupId',
  parentObjectId: 'parentObjectId',
  relativeX: 'relativeX',
  relativeY: 'relativeY',
  deletedAt: 'deletedAt',
  spaceId: 'spaceId',
  mapId: 'mapId',
  catalogItemVariantId: 'catalogItemVariantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MapV2ScalarFieldEnum = {
  id: 'id',
  status: 'status',
  baseAreaId: 'baseAreaId',
  floorId: 'floorId',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingParticipantV2ScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId',
  meetingId: 'meetingId',
  inviterId: 'inviterId',
  isHost: 'isHost',
  inviteStatus: 'inviteStatus',
  responseStatus: 'responseStatus',
  didAttend: 'didAttend',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingParticipantV2RoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingV2ScalarFieldEnum = {
  id: 'id',
  status: 'status',
  title: 'title',
  description: 'description',
  recurringMeetingId: 'recurringMeetingId',
  meetingType: 'meetingType',
  visibility: 'visibility',
  actualStartDate: 'actualStartDate',
  actualEndDate: 'actualEndDate',
  canInviteesInviteOthers: 'canInviteesInviteOthers',
  canInviteesModifyDetails: 'canInviteesModifyDetails',
  canInviteesSeeOtherInvitees: 'canInviteesSeeOtherInvitees',
  areaId: 'areaId',
  spaceId: 'spaceId',
  primaryHostId: 'primaryHostId',
  isCanceled: 'isCanceled',
  meetingCreationDate: 'meetingCreationDate',
  areaLastAutoAssignedAt_TEMP: 'areaLastAutoAssignedAt_TEMP',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingV2RoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.OutfitTemplateScalarFieldEnum = {
  id: 'id',
  skin: 'skin',
  hair: 'hair',
  facialHair: 'facialHair',
  top: 'top',
  bottom: 'bottom',
  shoes: 'shoes',
  hat: 'hat',
  glasses: 'glasses',
  other: 'other',
  costume: 'costume',
  mobility: 'mobility',
  jacket: 'jacket',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.OutlookCalendarEventV2ScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  title: 'title',
  description: 'description',
  participants: 'participants',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RoleV2ScalarFieldEnum = {
  id: 'id',
  name: 'name',
  roleType: 'roleType',
  permissions: 'permissions',
  spaceId: 'spaceId',
  creatorId: 'creatorId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSettingsScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  guestCheckInEnabled: 'guestCheckInEnabled',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSettingsRoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceTemplateV2ScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserClusterScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOutfitScalarFieldEnum = {
  id: 'id',
  skin: 'skin',
  hair: 'hair',
  facialHair: 'facialHair',
  top: 'top',
  bottom: 'bottom',
  shoes: 'shoes',
  hat: 'hat',
  glasses: 'glasses',
  other: 'other',
  costume: 'costume',
  mobility: 'mobility',
  jacket: 'jacket',
  spaceId: 'spaceId',
  spaceUserId: 'spaceUserId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserV2ScalarFieldEnum = {
  id: 'id',
  authUserId: 'authUserId',
  name: 'name',
  spaceId: 'spaceId',
  floorId: 'floorId',
  profilePictureId: 'profilePictureId',
  followTargetId: 'followTargetId',
  clusterId: 'clusterId',
  deskId: 'deskId',
  shouldBeInClusterWithFollowTarget: 'shouldBeInClusterWithFollowTarget',
  online: 'online',
  handRaisedAt: 'handRaisedAt',
  speaking: 'speaking',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  position__x: 'position__x',
  position__y: 'position__y',
  direction__value: 'direction__value',
  speed__modifier: 'speed__modifier',
  userSetAvailability__value: 'userSetAvailability__value'
};

exports.Prisma.SpaceUserV2RoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceV2ScalarFieldEnum = {
  id: 'id',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceV2RoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.StudioUserSessionScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  mapId: 'mapId',
  spaceUserId: 'spaceUserId',
  isDirty: 'isDirty',
  isOpen: 'isOpen',
  lastHeartbeat: 'lastHeartbeat',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.StudioUserSessionRoleAssignmentScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserInvitationScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  email: 'email',
  role: 'role',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserMapHistoryScalarFieldEnum = {
  id: 'id',
  history: 'history',
  undoOffset: 'undoOffset',
  mapId: 'mapId',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.WearablePartV2ScalarFieldEnum = {
  id: 'id',
  spritesheetUrl: 'spritesheetUrl',
  layer: 'layer',
  animationType: 'animationType',
  wearableId: 'wearableId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.WearableV2ScalarFieldEnum = {
  id: 'id',
  color: 'color',
  name: 'name',
  type: 'type',
  previewUrl: 'previewUrl',
  lastSyncAuthoredAt: 'lastSyncAuthoredAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};
exports.DeviceType = exports.$Enums.DeviceType = {
  iOS: 'iOS',
  Android: 'Android',
  Unknown: 'Unknown'
};

exports.ExternalIntegrationType = exports.$Enums.ExternalIntegrationType = {
  GoogleCalendar: 'GoogleCalendar',
  Microsoft: 'Microsoft'
};

exports.SurveyResponseType = exports.$Enums.SurveyResponseType = {
  Denied: 'Denied',
  Completed: 'Completed',
  Incomplete: 'Incomplete'
};

exports.UseCase = exports.$Enums.UseCase = {
  Work: 'Work',
  Other: 'Other'
};

exports.SpacePlanOverride = exports.$Enums.SpacePlanOverride = {
  Premium: 'Premium',
  Trial: 'Trial',
  Free: 'Free'
};

exports.GatherLabsFeature = exports.$Enums.GatherLabsFeature = {
  WallsAndFloors: 'WallsAndFloors',
  MinimalisticSpaceView: 'MinimalisticSpaceView'
};

exports.SpaceChat = exports.$Enums.SpaceChat = {
  GlobalChat: 'GlobalChat',
  LocalChat: 'LocalChat',
  RoomChat: 'RoomChat'
};

exports.ExtensionType = exports.$Enums.ExtensionType = {
  Applause: 'Applause',
  ChatManager: 'ChatManager',
  GoKarts: 'GoKarts',
  Gong: 'Gong',
  HealthCheck: 'HealthCheck',
  MemberOnlyDoor: 'MemberOnlyDoor',
  NookManager: 'NookManager',
  PasswordDoor: 'PasswordDoor',
  Pets: 'Pets',
  SimpleDoor: 'SimpleDoor'
};

exports.SpaceRole = exports.$Enums.SpaceRole = {
  Mapmaker: 'Mapmaker',
  Mod: 'Mod',
  Owner: 'Owner',
  GeneralMember: 'GeneralMember',
  RecordingClient: 'RecordingClient',
  Guest: 'Guest'
};

exports.Permission = exports.$Enums.Permission = {
  UseDesk: 'UseDesk',
  MessageEveryone: 'MessageEveryone',
  UseBuildTool: 'UseBuildTool',
  UseMapmaker: 'UseMapmaker',
  InviteMember: 'InviteMember',
  KickUser: 'KickUser',
  BanUser: 'BanUser',
  SpotlightUser: 'SpotlightUser',
  EditSpacePermission: 'EditSpacePermission',
  EditSpaceAccess: 'EditSpaceAccess',
  EditReservation: 'EditReservation',
  UseMeetingRecording: 'UseMeetingRecording',
  UseInsights: 'UseInsights',
  UseSpaceFiles: 'UseSpaceFiles',
  ViewSubscription: 'ViewSubscription',
  EditSubscription: 'EditSubscription'
};

exports.SpaceTemplateArea = exports.$Enums.SpaceTemplateArea = {
  Indoor: 'Indoor',
  Outdoor: 'Outdoor',
  IndoorOutdoor: 'IndoorOutdoor'
};

exports.SpaceTemplateTab = exports.$Enums.SpaceTemplateTab = {
  Office: 'Office',
  Seasonal: 'Seasonal',
  Experience: 'Experience',
  Social: 'Social',
  Conference: 'Conference',
  Education: 'Education',
  Blank: 'Blank',
  Custom: 'Custom'
};

exports.SpaceTemplateSubtab = exports.$Enums.SpaceTemplateSubtab = {
  Tiny: 'Tiny',
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
  Holiday: 'Holiday'
};

exports.SpaceTemplateSize = exports.$Enums.SpaceTemplateSize = {
  num_1_to_5: 'num_1_to_5',
  num_2_to_25: 'num_2_to_25',
  num_5_to_25: 'num_5_to_25',
  num_25_to_50: 'num_25_to_50',
  num_50_to_100: 'num_50_to_100',
  num_1_orMore: 'num_1_orMore',
  num_100_orMore: 'num_100_orMore'
};

exports.SpawnTokenType = exports.$Enums.SpawnTokenType = {
  SpawnTile: 'SpawnTile',
  Nook: 'Nook',
  Desk: 'Desk',
  DefaultSpawnTile: 'DefaultSpawnTile'
};

exports.SpaceSSOConnectionState = exports.$Enums.SpaceSSOConnectionState = {
  Active: 'Active',
  Inactive: 'Inactive',
  Draft: 'Draft',
  Validating: 'Validating'
};

exports.SpaceSSOEmailDomainVerificationState = exports.$Enums.SpaceSSOEmailDomainVerificationState = {
  Pending: 'Pending',
  Verified: 'Verified'
};

exports.GuestPassStatus = exports.$Enums.GuestPassStatus = {
  Admitted: 'Admitted',
  Revoked: 'Revoked',
  Hidden: 'Hidden'
};

exports.SpaceUserStatus = exports.$Enums.SpaceUserStatus = {
  Available: 'Available',
  Busy: 'Busy',
  DoNotDisturb: 'DoNotDisturb'
};

exports.SpaceUserStatusEnd = exports.$Enums.SpaceUserStatusEnd = {
  NoneSelected: 'NoneSelected',
  ThirtyMinutes: 'ThirtyMinutes',
  OneHour: 'OneHour',
  TwoHours: 'TwoHours',
  Tomorrow: 'Tomorrow'
};

exports.SpaceUserStatusChangeSource = exports.$Enums.SpaceUserStatusChangeSource = {
  Manual: 'Manual',
  SleepManager: 'SleepManager'
};

exports.SpaceUserRequestStatus = exports.$Enums.SpaceUserRequestStatus = {
  PendingInvite: 'PendingInvite',
  PendingRequest: 'PendingRequest',
  Approved: 'Approved',
  Denied: 'Denied'
};

exports.SpaceUserRequestStatusChangeSource = exports.$Enums.SpaceUserRequestStatusChangeSource = {
  SpaceUser: 'SpaceUser',
  System: 'System'
};

exports.AdminRoleType = exports.$Enums.AdminRoleType = {
  Default: 'Default',
  Admin: 'Admin',
  Art: 'Art',
  ArtLead: 'ArtLead',
  Clients: 'Clients',
  ClientLead: 'ClientLead',
  Engineering: 'Engineering',
  Finance: 'Finance',
  MobileEngineering: 'MobileEngineering'
};

exports.UserOutfitTemplateType = exports.$Enums.UserOutfitTemplateType = {
  Onboarding: 'Onboarding',
  Initial: 'Initial'
};

exports.WearableLayer = exports.$Enums.WearableLayer = {
  MobilityBack: 'MobilityBack',
  OtherBack: 'OtherBack',
  HatBack: 'HatBack',
  HairBack: 'HairBack',
  JacketBack: 'JacketBack',
  SkinFront: 'SkinFront',
  BottomFront: 'BottomFront',
  ShoesFront: 'ShoesFront',
  TopFront: 'TopFront',
  JacketFront: 'JacketFront',
  OtherMiddle: 'OtherMiddle',
  GlassesFront: 'GlassesFront',
  FacialHairFront: 'FacialHairFront',
  HairFront: 'HairFront',
  HatFront: 'HatFront',
  OtherFront: 'OtherFront',
  MobilityFront: 'MobilityFront',
  CostumeFront: 'CostumeFront'
};

exports.WearableType = exports.$Enums.WearableType = {
  Skin: 'Skin',
  Hair: 'Hair',
  FacialHair: 'FacialHair',
  Top: 'Top',
  Bottom: 'Bottom',
  Shoes: 'Shoes',
  Hat: 'Hat',
  Glasses: 'Glasses',
  Other: 'Other',
  Costume: 'Costume',
  Mobility: 'Mobility',
  Jacket: 'Jacket'
};

exports.WearableSubType = exports.$Enums.WearableSubType = {
  Seasonal: 'Seasonal',
  Internal: 'Internal',
  Experimental: 'Experimental'
};

exports.MemberOnboardingStep = exports.$Enums.MemberOnboardingStep = {
  AvatarAndNameWelcome: 'AvatarAndNameWelcome',
  AvatarAndNameSelectAvatar: 'AvatarAndNameSelectAvatar',
  AvatarAndNameEnterName: 'AvatarAndNameEnterName',
  DeskSelection: 'DeskSelection',
  AVPermissions: 'AVPermissions',
  DesksGettingStarted: 'DesksGettingStarted',
  Desks101: 'Desks101',
  AVPrivacy: 'AVPrivacy',
  CalendarIntro: 'CalendarIntro',
  MeetingReminders: 'MeetingReminders',
  ConnectingCalendar: 'ConnectingCalendar',
  ChromeExtension: 'ChromeExtension',
  MigrateMeetings: 'MigrateMeetings',
  WalkingMovingAround: 'WalkingMovingAround',
  WalkingJoiningConversations: 'WalkingJoiningConversations',
  WalkingPrivateAreas: 'WalkingPrivateAreas',
  WalkingCelebrate: 'WalkingCelebrate'
};

exports.MemberOnboardingDesk = exports.$Enums.MemberOnboardingDesk = {
  Simple: 'Simple',
  Modern: 'Modern',
  Designer: 'Designer',
  Audiophile: 'Audiophile',
  Software: 'Software',
  Gamer: 'Gamer',
  Rustic: 'Rustic',
  Hardware: 'Hardware',
  Classic: 'Classic',
  Zen: 'Zen'
};

exports.CatalogItemCategory = exports.$Enums.CatalogItemCategory = {
  RoomDecor: 'RoomDecor',
  WallDecor: 'WallDecor',
  Table: 'Table',
  Seating: 'Seating',
  Nature: 'Nature',
  Machine: 'Machine',
  FoodAndDrink: 'FoodAndDrink',
  CabinetsAndShelves: 'CabinetsAndShelves',
  Interactive: 'Interactive'
};

exports.CatalogItemTagV2 = exports.$Enums.CatalogItemTagV2 = {
  Furniture: 'Furniture',
  Decoration: 'Decoration'
};

exports.CatalogItemOrientationV2 = exports.$Enums.CatalogItemOrientationV2 = {
  Down: 'Down',
  Right: 'Right',
  Up: 'Up',
  Left: 'Left'
};

exports.ExternalCalendarType = exports.$Enums.ExternalCalendarType = {
  Outlook: 'Outlook',
  Google: 'Google'
};

exports.UploadedFileType = exports.$Enums.UploadedFileType = {
  ScreenRecording: 'ScreenRecording',
  VirtualBackground: 'VirtualBackground',
  Profile: 'Profile'
};

exports.GoogleCalendarEventStatus = exports.$Enums.GoogleCalendarEventStatus = {
  Confirmed: 'Confirmed',
  Canceled: 'Canceled',
  Default: 'Default',
  Tentative: 'Tentative'
};

exports.GoogleCalendarEventType = exports.$Enums.GoogleCalendarEventType = {
  Birthday: 'Birthday',
  Default: 'Default',
  FocusTime: 'FocusTime',
  FromGmail: 'FromGmail',
  OutOfOffice: 'OutOfOffice',
  WorkingLocation: 'WorkingLocation'
};

exports.GuestPassState = exports.$Enums.GuestPassState = {
  Pending: 'Pending',
  Admitted: 'Admitted',
  Denied: 'Denied'
};

exports.MapAreaType = exports.$Enums.MapAreaType = {
  Public: 'Public',
  Lobby: 'Lobby',
  Common: 'Common',
  MeetingRoom: 'MeetingRoom',
  Team: 'Team',
  Desk: 'Desk'
};

exports.WallTextureKeyV2 = exports.$Enums.WallTextureKeyV2 = {
  NewStyleNoWall: 'NewStyleNoWall',
  NewStyleClean: 'NewStyleClean'
};

exports.GroundTextureKeyV2 = exports.$Enums.GroundTextureKeyV2 = {
  NewStyleSquares: 'NewStyleSquares',
  NewStyleTrianglesRugDark: 'NewStyleTrianglesRugDark',
  NewStyleTrianglesRug: 'NewStyleTrianglesRug',
  NewStyleGrass: 'NewStyleGrass',
  NewStyleGrassBordered: 'NewStyleGrassBordered',
  NewStyleWaterBordered: 'NewStyleWaterBordered',
  NewStylePlanks: 'NewStylePlanks',
  NewStylePlain: 'NewStylePlain',
  Grass: 'Grass',
  RugBlue: 'RugBlue',
  Wood: 'Wood',
  Snow: 'Snow'
};

exports.MapStatus = exports.$Enums.MapStatus = {
  Draft: 'Draft',
  Active: 'Active',
  Archived: 'Archived'
};

exports.MeetingParticipantInviteStatus = exports.$Enums.MeetingParticipantInviteStatus = {
  InvitedRequired: 'InvitedRequired',
  InvitedOptional: 'InvitedOptional',
  NotInvited: 'NotInvited'
};

exports.MeetingParticipantResponseStatus = exports.$Enums.MeetingParticipantResponseStatus = {
  Accepted: 'Accepted',
  TentativelyAccepted: 'TentativelyAccepted',
  Declined: 'Declined',
  Unknown: 'Unknown'
};

exports.MeetingStatus = exports.$Enums.MeetingStatus = {
  Pending: 'Pending',
  Confirmed: 'Confirmed'
};

exports.MeetingType = exports.$Enums.MeetingType = {
  Scheduled: 'Scheduled',
  Unplanned: 'Unplanned',
  Ambient: 'Ambient',
  External: 'External'
};

exports.MeetingVisibility = exports.$Enums.MeetingVisibility = {
  Public: 'Public',
  Private: 'Private'
};

exports.RoleV2Type = exports.$Enums.RoleV2Type = {
  Default: 'Default',
  Custom: 'Custom',
  Dynamic: 'Dynamic'
};

exports.PermissionV2 = exports.$Enums.PermissionV2 = {
  CanDestroy: 'CanDestroy',
  CanReset: 'CanReset',
  CanRespond: 'CanRespond',
  AcceptMeeting: 'AcceptMeeting',
  AddParticipant: 'AddParticipant',
  RemoveParticipant: 'RemoveParticipant',
  UseBuildTool: 'UseBuildTool',
  UseStudio: 'UseStudio',
  UpdateUserRole: 'UpdateUserRole',
  InviteAdmin: 'InviteAdmin',
  InviteMemberOrGuest: 'InviteMemberOrGuest',
  ModifyOwnUser: 'ModifyOwnUser',
  UpdateSpaceSetting: 'UpdateSpaceSetting',
  EditSessionData: 'EditSessionData'
};

exports.Direction = exports.$Enums.Direction = {
  Up: 'Up',
  Down: 'Down',
  Left: 'Left',
  Right: 'Right'
};

exports.Availability = exports.$Enums.Availability = {
  Available: 'Available',
  Busy: 'Busy',
  Away: 'Away',
  Offline: 'Offline'
};

exports.CoreRoleType = exports.$Enums.CoreRoleType = {
  Admin: 'Admin',
  Member: 'Member',
  Guest: 'Guest'
};

exports.WearableLayerV2 = exports.$Enums.WearableLayerV2 = {
  MobilityBack: 'MobilityBack',
  OtherBack: 'OtherBack',
  HatBack: 'HatBack',
  HairBack: 'HairBack',
  JacketBack: 'JacketBack',
  SkinFront: 'SkinFront',
  BottomFront: 'BottomFront',
  ShoesFront: 'ShoesFront',
  TopFront: 'TopFront',
  JacketFront: 'JacketFront',
  OtherMiddle: 'OtherMiddle',
  GlassesFront: 'GlassesFront',
  FacialHairFront: 'FacialHairFront',
  HairFront: 'HairFront',
  HatFront: 'HatFront',
  OtherFront: 'OtherFront',
  MobilityFront: 'MobilityFront',
  CostumeFront: 'CostumeFront'
};

exports.AnimationType = exports.$Enums.AnimationType = {
  Essentials: 'Essentials',
  Extras: 'Extras'
};

exports.WearableTypeV2 = exports.$Enums.WearableTypeV2 = {
  Skin: 'Skin',
  Hair: 'Hair',
  FacialHair: 'FacialHair',
  Top: 'Top',
  Bottom: 'Bottom',
  Shoes: 'Shoes',
  Hat: 'Hat',
  Glasses: 'Glasses',
  Other: 'Other',
  Costume: 'Costume',
  Mobility: 'Mobility',
  Jacket: 'Jacket'
};

exports.Prisma.ModelName = {
  ApiKey: 'ApiKey',
  BrowserVideoClientStateSession: 'BrowserVideoClientStateSession',
  UserDevice: 'UserDevice',
  ExternalIntegration: 'ExternalIntegration',
  File: 'File',
  GoogleToken: 'GoogleToken',
  InviteToken: 'InviteToken',
  LegacyInviteToken: 'LegacyInviteToken',
  ObjectTemplateCategory: 'ObjectTemplateCategory',
  ObjectTemplate: 'ObjectTemplate',
  ObjectVariant: 'ObjectVariant',
  ObjectVariantSpritesheet: 'ObjectVariantSpritesheet',
  ObjectVariantSpritesheetAnimation: 'ObjectVariantSpritesheetAnimation',
  ScreenAnnotation: 'ScreenAnnotation',
  SurveyResponse: 'SurveyResponse',
  Recording: 'Recording',
  RecordingExtraBudgets: 'RecordingExtraBudgets',
  Sound: 'Sound',
  Space: 'Space',
  SpaceCapacityWarning: 'SpaceCapacityWarning',
  SpaceCapacityWarningUser: 'SpaceCapacityWarningUser',
  SpaceExtension: 'SpaceExtension',
  SpaceRolePermissionOverride: 'SpaceRolePermissionOverride',
  SpaceTemplate: 'SpaceTemplate',
  SpawnToken: 'SpawnToken',
  SpaceWorkOSConnection: 'SpaceWorkOSConnection',
  SpaceSSOSettings: 'SpaceSSOSettings',
  SpaceSSOEmailDomain: 'SpaceSSOEmailDomain',
  SpaceSSOLinkedSpace: 'SpaceSSOLinkedSpace',
  SpaceSendBirdApplication: 'SpaceSendBirdApplication',
  SpaceUser: 'SpaceUser',
  SpaceUserRequest: 'SpaceUserRequest',
  SpaceUserSetting: 'SpaceUserSetting',
  SpaceUserSettingPinnedUser: 'SpaceUserSettingPinnedUser',
  SpaceUserDesk: 'SpaceUserDesk',
  User: 'User',
  UserDeletionForStaging: 'UserDeletionForStaging',
  UserFeedback: 'UserFeedback',
  UserOutfitTemplate: 'UserOutfitTemplate',
  UserOutfit: 'UserOutfit',
  WearablePart: 'WearablePart',
  Wearable: 'Wearable',
  SpaceUserOnboarding: 'SpaceUserOnboarding',
  SpaceUserOnboardingChecklist: 'SpaceUserOnboardingChecklist',
  SpaceUserOnboardingChecklistItem: 'SpaceUserOnboardingChecklistItem',
  CalendarEventV2: 'CalendarEventV2',
  CatalogItemV2: 'CatalogItemV2',
  CatalogItemVariantV2: 'CatalogItemVariantV2',
  ExternalCalendarConnectionAccessV2: 'ExternalCalendarConnectionAccessV2',
  ExternalCalendarConnectionAccessV2RoleAssignment: 'ExternalCalendarConnectionAccessV2RoleAssignment',
  ExternalCalendarConnectionSecretsV2: 'ExternalCalendarConnectionSecretsV2',
  ExternalCalendarConnectionV2: 'ExternalCalendarConnectionV2',
  ExternalCalendarConnectionV2RoleAssignment: 'ExternalCalendarConnectionV2RoleAssignment',
  ExternalCalendarV2: 'ExternalCalendarV2',
  ExternalCalendarV2RoleAssignment: 'ExternalCalendarV2RoleAssignment',
  FileV2: 'FileV2',
  FloorV2: 'FloorV2',
  GoogleCalendarEventV2: 'GoogleCalendarEventV2',
  GroupUserV2: 'GroupUserV2',
  GroupV2: 'GroupV2',
  GuestPassV2: 'GuestPassV2',
  GuestPassV2RoleAssignment: 'GuestPassV2RoleAssignment',
  MapAreaV2: 'MapAreaV2',
  MapEntityIdentifier: 'MapEntityIdentifier',
  MapGroupV2: 'MapGroupV2',
  MapObjectV2: 'MapObjectV2',
  MapV2: 'MapV2',
  MeetingParticipantV2: 'MeetingParticipantV2',
  MeetingParticipantV2RoleAssignment: 'MeetingParticipantV2RoleAssignment',
  MeetingV2: 'MeetingV2',
  MeetingV2RoleAssignment: 'MeetingV2RoleAssignment',
  OutfitTemplate: 'OutfitTemplate',
  OutlookCalendarEventV2: 'OutlookCalendarEventV2',
  RoleV2: 'RoleV2',
  SpaceSettings: 'SpaceSettings',
  SpaceSettingsRoleAssignment: 'SpaceSettingsRoleAssignment',
  SpaceTemplateV2: 'SpaceTemplateV2',
  SpaceUserCluster: 'SpaceUserCluster',
  SpaceUserOutfit: 'SpaceUserOutfit',
  SpaceUserV2: 'SpaceUserV2',
  SpaceUserV2RoleAssignment: 'SpaceUserV2RoleAssignment',
  SpaceV2: 'SpaceV2',
  SpaceV2RoleAssignment: 'SpaceV2RoleAssignment',
  StudioUserSession: 'StudioUserSession',
  StudioUserSessionRoleAssignment: 'StudioUserSessionRoleAssignment',
  UserInvitation: 'UserInvitation',
  UserMapHistory: 'UserMapHistory',
  WearablePartV2: 'WearablePartV2',
  WearableV2: 'WearableV2'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        const runtime = detectRuntime()
        const edgeRuntimeName = {
          'workerd': 'Cloudflare Workers',
          'deno': 'Deno and Deno Deploy',
          'netlify': 'Netlify Edge Functions',
          'edge-light': 'Vercel Edge Functions or Edge Middleware',
        }[runtime]

        let message = 'PrismaClient is unable to run in '
        if (edgeRuntimeName !== undefined) {
          message += edgeRuntimeName + '. As an alternative, try Accelerate: https://pris.ly/d/accelerate.'
        } else {
          message += 'this browser environment, or has been bundled for the browser (running in `' + runtime + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)

import { matchPath } from "react-router-dom"

import { getSpaceIdFromPath as getSpaceIdFromPathCommon } from "gather-common/dist/src/public/utils"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import {
  SPACE_PATH_PATTERN,
  SPACE_STUDIO_PATH_PATTERN,
} from "gather-http-common/dist/src/public/url"
import { getWindowLocation } from "./browser"

export function getInSpaceV2() {
  const pathToParse = decodeURIComponent(getWindowLocation().pathname)

  const match = matchPath(
    {
      path: SPACE_PATH_PATTERN,
      end: false,
      caseSensitive: true,
    },
    pathToParse,
  )

  return !!match
}

export function getInStudio(): boolean {
  const pathToParse = decodeURIComponent(getWindowLocation().pathname)

  const match = matchPath(
    {
      path: SPACE_STUDIO_PATH_PATTERN,
      end: true,
      caseSensitive: true,
    },
    pathToParse,
  )

  return !!match
}

export function getInStudioMainApp(): boolean {
  // TODO APP-8564: Remove getInStudioMainApp method
  return getInSpaceV2()
}

export function getSpaceIdFromPath() {
  const pathToParse = decodeURIComponent(getWindowLocation().pathname)

  return getSpaceIdFromPathCommon(pathToParse)
}

// Use for convenience from callsites where there should always be a room to return.
export function getAndAssertSpaceIdFromPath(): Uuid {
  const spaceId = getSpaceIdFromPath()
  if (spaceId === null) throw new Error("getAndAssertSpaceIdFromPath failed to find the spaceId!")

  return spaceId
}

// 'Gather App View' help functions
export function getInChatTab(): boolean {
  const pathToParse = decodeURIComponent(getWindowLocation().pathname)

  const match = matchPath(
    {
      path: `${SPACE_PATH_PATTERN}/chat`,
      end: true,
      caseSensitive: true,
    },
    pathToParse,
  )

  return !!match
}

export function getInCalendarTab(): boolean {
  const pathToParse = decodeURIComponent(getWindowLocation().pathname)

  const match = matchPath(
    {
      path: `${SPACE_PATH_PATTERN}/calendar`,
      end: true,
      caseSensitive: true,
    },
    pathToParse,
  )

  return !!match
}

export function getInOfficeViewTab(): boolean {
  const pathToParse = decodeURIComponent(getWindowLocation().pathname)

  // No postfix for office view
  const match = matchPath(
    {
      path: SPACE_PATH_PATTERN,
      end: true,
      caseSensitive: true,
    },
    pathToParse,
  )

  return !!match
}

export {
  DASHBOARD_PATH_PATTERN,
  getDashboardPath,
  getDashboardPathOrThrow,
  getHumanReadableSpacePath,
  getPathToHome,
  getSpacePathFromId,
  getSpacePathFromIdOrThrow,
  getSpaceStudioPathFromId,
  getURLBaseDomain,
  getUrlFromSpaceId,
  isLocalGatherOrigin,
  isURLFromValidGatherOrigin,
  SPACE_PATH_PATTERN,
  SPACE_STUDIO_PATH_PATTERN,
} from "../url"

import type {
  AxiosAdapter as AxiosAdapterLib,
  AxiosError as AxiosErrorLib,
  AxiosRequestConfig as AxiosRequestConfigLib,
  AxiosResponse as AxiosResponseLib,
  CancelTokenSource as CancelTokenSourceLib,
} from "axios"
import axios, { AxiosError } from "axios"

import { StatusCodes } from "./http"

export { axios }
export type {
  AxiosAdapterLib as AxiosAdapter,
  AxiosErrorLib as AxiosError,
  AxiosRequestConfigLib as AxiosRequestConfig,
  AxiosResponseLib as AxiosResponse,
  CancelTokenSourceLib as CancelTokenSource,
}

import axiosRetry, { exponentialDelay } from "axios-retry"

// set up exponential backoff for retries
// by default it will retry network errors and 5xx errors for idempotent verbs (GET, HEAD, OPTIONS, PUT, DELETE)
axiosRetry(axios, {
  // exponential backoff starting at 1 second
  retryDelay: (retryCount, error) => exponentialDelay(retryCount, error, 1000),
  retries: 5,
})

export const hasAPIErrorCode = <T>(error: AxiosError | Error | unknown, errorCode: T): boolean =>
  axios.isAxiosError(error) && error.response?.data?.code === errorCode

export const hasAPIStatusCode = (
  error: AxiosError | Error | unknown,
  statusCode: StatusCodes,
): boolean => axios.isAxiosError(error) && error.response?.status === statusCode
